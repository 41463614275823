import React, { useState } from "react";
import {
    Button,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Modal,
    Row,
} from "reactstrap";
import AsyncSelect from "react-select/async";
import apiGlobal, { handleApiError } from "global/api.global";
import { errorToast, successToast } from "Components/Toasts";
import { arraySubtract } from "GenericForms/Helper";
import { customStyle } from "../../../shared/CommonCSS";
import { RootState } from "index";
import { useSelector } from "react-redux";
import { AdjustmentApprovalStatus } from "shared/constants";

const CIIAdjustmentModal = (props: any) => {
    const {
        state,
        setState,
        ongoingEvents,
        record,
        loadCIIAdjustments,
        reportId,
        vesselId,
        adjustmentEvents,
    } = props;
    const { VesselID, Vessels } = useSelector(
        (state: RootState) => state.Reporting
    );
    let vessel = Vessels.find((rec: any) => rec.id === VesselID) ?? null;
    let [CIIData] = useState<Array<any>>([]);

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function tog_backdrop() {
        setState(!state);
        removeBodyCss();
    }

    const loadCIIAdjustmentOptions = async () => {
        try {
            const response = await apiGlobal.get(`/cii_adjustment_events_master/`);
            let res = response.data.filter(
                (item: any) =>
                    item.precedence_id === 0 &&
                    (vessel?.ice_class === "NA" ? item.id !== 8 : "")
            );
            if (ongoingEvents.length > 0) {
                let subtractedArray = arraySubtract(res, ongoingEvents);
                return arraySubtract(subtractedArray, adjustmentEvents);
            }
            return res;
        } catch (error: any) {
            handleApiError(error);
        }
    };

    const handleChange = (e: any, actionMeta: any) => {
        if (actionMeta.action === "remove-value") {
            const indexToRemove = CIIData.findIndex(
                (item: any) =>
                    item.cii_adjustment_begin_event === actionMeta.removedValue.id
            );
            if (indexToRemove !== -1) {
                CIIData?.splice(indexToRemove, 1);
            }
        }
        if (actionMeta.action === "select-option") {
            CIIData?.push({
                user_approved: AdjustmentApprovalStatus.APPROVED,
                class_approved: false,
                cii_begin_date_time: record.reporting_time_utc,
                cii_end_date_time: null,
                cii_adjustment_status: true,
                cii_begin_report: reportId,
                cii_adjustment_begin_event: actionMeta.option.id,
                cii_end_report: null,
                cii_adjustment_end_event: null,
                cii_adjustment: actionMeta.option.cii_adjustment,
                file_upload_status: false,
                vessel: vesselId,
            });
        }
    };
    const CIIAdjustmentSubmit = async () => {
        try {
            if (CIIData[0]?.cii_adjustment) {
                setEventSave(true);
                const response = await apiGlobal.post(
                    `/vessel_reporting_cii_adjustment/`,
                    CIIData
                );
                if (response.status === 200 || response.status === 201) {
                    setEventSave(false);
                    CIIData.length = 0;
                    loadCIIAdjustments();
                    successToast("Data saved successfully!");
                }
                setState(false);
                return response.data;
            }
        } catch (err) {
            errorToast("Error. Unable to save!");
            setEventSave(false);
            return null;
        }
    };

    const [eventSave, setEventSave] = useState(false);

    return (
        <Modal
            isOpen={state}
            toggle={() => {
                tog_backdrop();
            }}
            backdrop={"static"}
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                    Start New Event
                </h5>
                <button
                    onClick={() => {
                        setState(false);
                        setEventSave(false);
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body p-0 m-0">
                <Card className="m-0">
                    <CardBody>
                        <Form
                            className="needs-validation"
                            onSubmit={(e) => {
                                e.preventDefault();
                                CIIAdjustmentSubmit();
                                return false;
                            }}
                        >
                            <Row>
                                <FormGroup className="form-floating mb-3">
                                    <div className="mb-3">
                                        <Label className="asteric" for="cii_adjustment">
                                            Select CII adjustment event
                                        </Label>
                                        <AsyncSelect
                                            name="cii_adjustment"
                                            inputId="cii_adjustment"
                                            cacheOptions
                                            defaultOptions
                                            loadOptions={loadCIIAdjustmentOptions}
                                            getOptionLabel={(e: any) => e.adjustment_event_name}
                                            getOptionValue={(e) => e.id}
                                            onChange={handleChange}
                                            isMulti
                                            menuPortalTarget={document.querySelector(
                                                ".MuiDialog-root"
                                            )} // fix for the modelbox
                                            styles={customStyle}
                                        />
                                    </div>
                                </FormGroup>
                            </Row>
                            <div className="d-flex flex-wrap gap-5 grp_justify_right">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn_size4_5_cstm"
                                    disabled={eventSave}
                                >
                                    Save
                                </Button>
                                <Button
                                    type="reset"
                                    color="danger"
                                    className="btn_size4_5_cstm"
                                >
                                    Reset
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        </Modal>
    );
};

export default CIIAdjustmentModal;
