import React, { useState } from 'react';
import { Card, NavItem, TabContent, TabPane, NavLink, Container, Nav, CardHeader, Row, Col } from "reactstrap";
import Layout from "../../HorizontalMenu/Menu";
import Navbar from "../../HorizontalMenu/Navbar";
import classnames from "classnames";
import '../../global/GlobalCSS.css'
import GeneralSettings from './VesselMachinery/GeneralSettings/GeneralSetings';
import SFOC from './SFOC';
import Engines from './Engines';
import Fuels from './Fuel';
import SpecialOperations from './SpecialOperations/SpecialOperations';
import LubeOilMaster from './LubeOilMaster';
import SensorsMaster from './SensorsMaster';
import VesselDetailsHeader from '../../Components/VesselDetailsHeader';
import { useDispatch, useSelector } from 'react-redux';
import { VesselState, setVesselState } from "../../Store/Generic/ReportingSlice";
import VesselMachineryConfig from './VesselMachinery/VesselMachineryConfig';
import Other from './Other/Other';
import { VesselTypeConstant } from 'shared/constants';
import { RootState } from 'index';

const VesselConfiguration = () => {
    const [activeSettingsTab, setActiveSettingsTab] = useState('1');
    const dispatch = useDispatch();
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);

    const toggle = (tab: any) => {
        if (activeSettingsTab !== tab) {
            setActiveSettingsTab(tab);
        }
    }

    const handleVesselState = (record: VesselState) => {
        dispatch(setVesselState(record))
    }

    return (
        <>
            <React.Fragment>
                <Layout children={Navbar} />
                <div className="page-content">
                    <Container fluid>

                        <Card>
                            <CardHeader className="px-2 py-0">
                                <Row className='pb-2'>
                                    <Col sm="2" className="d-flex align-items-center">
                                        <button color='primary' className='btn btn-primary' onClick={() => { handleVesselState(null) }}>
                                            <i className="bx bx-chevron-left me-1" /> Back
                                        </button>
                                    </Col>
                                    <Col sm="10">
                                        <VesselDetailsHeader />
                                    </Col>
                                </Row>
                                <Nav tabs className='border-0'>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === "1",
                                            })}
                                            onClick={() => {
                                                toggle("1");
                                            }}
                                        >
                                            Machinery
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === "2",
                                            })}
                                            onClick={() => {
                                                toggle("2");
                                            }}
                                        >
                                            General Settings
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === "3",
                                            })}
                                            onClick={() => {
                                                toggle("3");
                                            }}
                                        >
                                            SFOC
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === "4",
                                            })}
                                            onClick={() => {
                                                toggle("4");
                                            }}
                                        >
                                            Engines
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === "5",
                                            })}
                                            onClick={() => {
                                                toggle("5");
                                            }}
                                        >
                                            Fuel
                                        </NavLink>
                                    </NavItem>
                                    {Object.values(VesselTypeConstant).includes(vessel?.vessel_type) &&
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: activeSettingsTab === "6",
                                                })}
                                                onClick={() => {
                                                    toggle("6");
                                                }}
                                            >
                                                Special operations
                                            </NavLink>
                                        </NavItem>
                                    }
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === "7",
                                            })}
                                            onClick={() => {
                                                toggle("7");
                                            }}
                                        >
                                            Lube Oil
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === "8",
                                            })}
                                            onClick={() => {
                                                toggle("8");
                                            }}
                                        >
                                            Sensors
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === "9",
                                            })}
                                            onClick={() => {
                                                toggle("9");
                                            }}
                                        >
                                            Other
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <TabContent activeTab={activeSettingsTab} className="text-muted">
                                <TabPane tabId="1" className='margin-3'>
                                    <VesselMachineryConfig />
                                </TabPane>
                                <TabPane tabId="2" className='margin-3'>
                                    <GeneralSettings />
                                </TabPane>
                                <TabPane tabId="3" className='margin-3'>
                                    <SFOC />
                                </TabPane>
                                <TabPane tabId="4" className='margin-3'>
                                    <Engines />
                                </TabPane>
                                <TabPane tabId="5" className='margin-3'>
                                    <Fuels />
                                </TabPane>
                                <TabPane tabId="6" className='margin-3'>
                                    <SpecialOperations />
                                </TabPane>
                                <TabPane tabId="7" className='margin-3'>
                                    <LubeOilMaster />
                                </TabPane>
                                <TabPane tabId="8" className='margin-3'>
                                    <SensorsMaster />
                                </TabPane>
                                <TabPane tabId="9" className='margin-3'>
                                    <Other />
                                </TabPane>
                            </TabContent>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        </>
    )
}

export default VesselConfiguration;