import ToolTip from 'Components/ToolTip';
import apiGlobal from 'global/api.global';
import { RootState } from 'index';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Row, Col, Label } from 'reactstrap'
import STSAdjustmentPopup from './STSAdjustmentPopup';
import { Roles } from 'shared/constants';
import { hasRole } from 'utils/auth/authUtils';
import STSAjustmentTableHeadComponent from './STSAjustmentTableHeadComponent';
import AdjustmentPaginationComponent from 'Adjustments/AdjustmentPaginationComponent';
import ValidateIcon from '../../Media/VLD1.png'

const ApprovedSTSAdjustment = ({ active }: any) => {
    /** Statae variables */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [tableDetails, setTableDetails] = useState({
        size: 5,
        index: 0,
        stsAdjustment: []
    })
    const [count, setCount] = useState(0);
    const [adjustmentPopup, setAdjustmentPopup] = useState(false);
    const [eventState, setEventState] = useState<any>({});
    /** State variables end */

    /** Load spproved STS operations for the selected vessel */
    const loadData = async () => {
        await apiGlobal.get(`sts_operation_detail/get_sts_operation_details_by_vessel/?vessel_id=${VesselID}`).then(response => {
            setTableDetails(prevDetails => ({
                ...prevDetails,
                stsAdjustment: response.data.filter((item: any) => item.user_approved === false)
            }))
            setCount(count => count + 1);
        }).catch(err => {
            setTableDetails(prevDetails => ({
                ...prevDetails,
                stsAdjustment: []
            }))
        })
    };

    /** useEffect start */
    useEffect(() => {
        loadData();
    }, [VesselID, active])
    /** useEffect end */

    /** Open-close modal */
    function tog_backdrop() {
        setAdjustmentPopup(!adjustmentPopup);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    return (
        <>
            {tableDetails?.stsAdjustment?.length === 0 ?
                <div className='text-center'>
                    <Label className='mb-3'>No STS Adjustments are approved for this vessel</Label>
                </div> :
                <>
                    <Row>
                        <Col lg={12}>
                            <div className="table-responsive">
                                <table className="table mb-0" key={count}>
                                    <STSAjustmentTableHeadComponent />
                                    <tbody>
                                        {tableDetails && tableDetails?.stsAdjustment?.slice(tableDetails?.index * tableDetails?.size,
                                            tableDetails?.index * tableDetails?.size + tableDetails?.size)?.map((sts: any, index: number) => {
                                                return (
                                                    <tr>
                                                        <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                        <td className='p-2 align-middle'>
                                                            <>
                                                                <b>{sts?.sts_start_time?.toString().substring(0, 10)}&nbsp;
                                                                    {sts?.sts_start_time?.toString().substring(11, 16)}</b> to
                                                                <b> {sts?.sts_end_time?.toString().substring(0, 10)}&nbsp;
                                                                    {sts?.sts_end_time?.toString().substring(11, 16)}</b>
                                                            </>
                                                        </td>
                                                        <td className='p-2 align-middle'>{sts?.sts_loading === true ? <Label>STS Loading</Label> :
                                                            sts?.sts_discharging === true ? <Label className='mb-0'>STS Discharging</Label> :
                                                                <Label className='mb-0'>STS Post Discharging</Label>}
                                                        </td>
                                                        {!(hasRole(Roles.CHIEFENGINEER)) &&
                                                            <td className='nopadtop d-flex flex-warp p-2 gap-2 justify-content-center'>
                                                                <img
                                                                    src={ValidateIcon}
                                                                    alt="Validate Icon"
                                                                    id={`sts_validate${index}`}
                                                                    className='text-centre pointer'
                                                                    height="30"
                                                                    onClick={() => {
                                                                        setAdjustmentPopup(true);
                                                                        setEventState(sts);
                                                                    }}
                                                                />
                                                                <ToolTip target={`sts_validate${index}`} message='Validate STS Adjustment' />
                                                            </td>
                                                        }
                                                    </tr>
                                                )
                                            })}
                                        {adjustmentPopup === true &&
                                            <STSAdjustmentPopup
                                                state={adjustmentPopup}
                                                setState={setAdjustmentPopup}
                                                toggle={tog_backdrop}
                                                event={eventState}
                                                target={`popup${eventState?.id}`}
                                            />
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                    {tableDetails?.stsAdjustment?.length > tableDetails?.size &&
                        <AdjustmentPaginationComponent
                            count={count}
                            setCount={setCount}
                            tableDetails={tableDetails}
                            setTableDetails={setTableDetails}
                        />
                    }
                </>
            }
        </>
    )
}
export default ApprovedSTSAdjustment