import { Component } from 'react'

interface ErrorBoundaryState {
    hasError: boolean;
}

export class ErrorBoundary extends Component<any, ErrorBoundaryState> {
    constructor(props: any) {
        super(props);
        this.state = {
            hasError: false
        }
    }
    static getDerivedStateFromError(error: any): ErrorBoundaryState {
        return {
            hasError: true
        }
    }
    render() {
        return this.props.children
    }
}

export default ErrorBoundary