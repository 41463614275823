import Layout from "HorizontalMenu/Menu";
import { setVesselID } from "Store/Generic/ReportingSlice";
import { RootState } from "index";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Row,
    Label,
    Container,
    Navbar,
    Card,
    CardHeader,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import PendingSTSAdjustment from "./PendingSTSAdjustment";
import ApprovedSTSAdjustment from "./ApprovedSTSAdjustment";
import { customStyle } from "shared/CommonCSS";
import { VesselTypeConstant } from "shared/constants";

const STSAdjustment = () => {
    /** State variables start */
    const dispatch = useDispatch();
    const { Vessels } = useSelector((state: RootState) => state.Reporting);
    const [activeAdjustmentsTab, setActiveAdjustmentsTab] = useState("1");
    const [tab, setTab] = useState("pending");
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    /** State variables end */

    /** set global vessel */
    const handleVesselSelectionChanges = (e: any) => {
        dispatch(setVesselID(e.id));
    };

    /** Change tabs */
    const toggle = (tab: any) => {
        if (activeAdjustmentsTab !== tab) {
            setActiveAdjustmentsTab(tab);
        }
    };

    return (
        <React.Fragment>
            <Layout children={Navbar} />
            <div className="page-content">
                <Container fluid>
                    <Row className="mb-1">
                        <h4>STS Adjustments</h4>
                    </Row>
                    <Row className="mb-2">
                        <Col sm={4}>
                            <Label className="mb-0">Select vessel</Label>
                            <Select
                                options={
                                    Vessels.filter(
                                        (item: any) =>
                                            item.display === true &&
                                            item.vessel_type === VesselTypeConstant.TANKER
                                    ) as any[]
                                }
                                getOptionLabel={(option: any) => option.vessel}
                                getOptionValue={(option: any) => option.id}
                                onChange={(e: any) => handleVesselSelectionChanges(e)}
                                menuPortalTarget={document.body}
                                styles={customStyle}
                                defaultValue={{
                                    id: VesselID,
                                    vessel_name: vessel?.vessel_name,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3 px-2">
                        <Card className="p-0">
                            <CardHeader className="pb-0">
                                <Nav tabs className="border-0">
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeAdjustmentsTab === "1",
                                            })}
                                            onClick={() => {
                                                setTab("pending");
                                                toggle("1");
                                            }}
                                        >
                                            Pending
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeAdjustmentsTab === "2",
                                            })}
                                            onClick={() => {
                                                setTab("approved");
                                                toggle("2");
                                            }}
                                        >
                                            Approved
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <TabContent
                                activeTab={activeAdjustmentsTab}
                                className="text-muted"
                            >
                                <TabPane tabId="1" className="mx-0 mt-2">
                                    <PendingSTSAdjustment active={tab} />
                                </TabPane>
                                <TabPane tabId="2" className="mx-0 mt-2">
                                    <ApprovedSTSAdjustment active={tab} />
                                </TabPane>
                            </TabContent>
                        </Card>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default STSAdjustment;
