import PopOver from 'Components/PopOver';
import { errorToast, successToast } from 'Components/Toasts';
import { errResponse } from 'GenericForms/Helper';
import apiGlobal from 'global/api.global';
import { RootState } from 'index';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Label, Row, Col, Button, Modal } from 'reactstrap';
import { AdjustmentApprovalStatus, Roles } from 'shared/constants';
import { hasRole } from 'utils/auth/authUtils';
import N2AdjustmentTableHeadComponent from './N2AdjustmentTableHeadComponent';
import AdjustmentPaginationComponent from 'Adjustments/AdjustmentPaginationComponent';
import ToolTip from 'Components/ToolTip';
import ViewReport from '../../Media/VD2.png'
import LNGCargoQuality from 'Components/LNGCargoQuality';

const ApprovedN2Adjustment = ({
    active
}: any) => {
    /** State variables */
    const [tableDetails, setTableDetails] = useState({
        size: 5,
        index: 0,
        n2Adjustment: []
    });
    const [count, setCount] = useState(0);
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [n2adjustment, setN2Adjustment] = useState<any>({});
    const [disapproveBool, setDisapproveBool] = useState<any>({});
    const [viewDetailsBool, setViewDetailsBool] = useState(false);
    /** State variables end */

    /** useEffect start */
    useEffect(() => {
        loadData();
    }, [VesselID, active])
    /** useEffect end */

    /** Load N2 corrections of selected vessel that are already approved */
    const loadData = async () => {
        await apiGlobal.get(`n2_adjustment/get_n2_adjustment_by_vessel/?vessel_id=${VesselID}`).then(response => {
            setTableDetails(prevDetails => ({
                ...prevDetails,
                n2Adjustment: response.data.filter((item: any) => item.approval_status === AdjustmentApprovalStatus.APPROVED)
            }));
            setCount(count => count + 1);
        }).catch(err => {
            setTableDetails(prevDetails => ({
                ...prevDetails,
                n2Adjustment: []
            }));
        })
    };
    
    /** Function to disapprove N2 Correction */
    const disapproveN2Correction = () => {
        n2adjustment.approval_status = AdjustmentApprovalStatus.PENDING;
        apiGlobal.put(`/n2_adjustment/${n2adjustment?.id}/`, n2adjustment).then(res => {
            if (res.status === 200) {
                successToast("Data saved successfully!");
                loadData();
                setDisapproveBool(false);
            }
        }).catch(err => {
            if (errResponse.includes(err?.response?.status)) {
                errorToast("Internal error occured, please contact the admin");
            }
        });
    }
    return (
        <>
            {tableDetails?.n2Adjustment?.length === 0 ?
                <div className='text-center'>
                    <Label className='mb-3'>No N<sub>2</sub> corrections are approved for this vessel</Label>
                </div> :
                <>
                    <Row className='mb-0'>
                        <Col lg={7}>
                            <div className="table-responsive">
                                <table className="table mb-0" key={count}>
                                    <N2AdjustmentTableHeadComponent />
                                    <tbody>
                                        {tableDetails && tableDetails?.n2Adjustment?.slice(tableDetails?.index * tableDetails?.size,
                                            tableDetails?.index * tableDetails?.size + tableDetails?.size)?.map((n2: any, index: number) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className='nopadtop align-middle p-2 text-center'>{index + 1}</td>
                                                        <td className='nopadtop align-middle p-2'>
                                                            <>
                                                                <b>{n2?.start_date_time?.toString().substring(0, 10)}&nbsp;
                                                                    {n2?.start_date_time?.toString().substring(11, 16)}</b> to
                                                                <b> {n2?.end_date_time?.toString().substring(0, 10)}&nbsp;
                                                                    {n2?.end_date_time?.toString().substring(11, 16)}</b>
                                                            </>
                                                        </td>
                                                        {!(hasRole(Roles.CHIEFENGINEER)) &&
                                                            <>
                                                                <td>
                                                                    <ToolTip target='view_details' message='View details' />
                                                                    <img
                                                                        alt="View Report"
                                                                        className='align-middle pointer'
                                                                        src={ViewReport}
                                                                        height="29"
                                                                        id='view_details'
                                                                        onClick={() => {
                                                                            setN2Adjustment(n2);
                                                                            setViewDetailsBool(true);
                                                                        }}
                                                                    />

                                                                    <Modal
                                                                        size='xl'
                                                                        isOpen={viewDetailsBool}
                                                                        toggle={() => {
                                                                            setViewDetailsBool(!viewDetailsBool);
                                                                        }}
                                                                        backdrop={"static"}
                                                                        id="staticBackdrop"
                                                                    >
                                                                        <div className="modal-header p-1 d-flex align-items-center">
                                                                            <h5 className="modal-title mb-0" id="staticBackdropLabel">
                                                                                LNG Cargo Quality Details
                                                                            </h5>
                                                                            <button
                                                                                onClick={() => {
                                                                                    setViewDetailsBool(false);
                                                                                }}
                                                                                type="button"
                                                                                className="close pb-1"
                                                                                data-dismiss="modal"
                                                                                aria-label="Close"
                                                                            >
                                                                                <span aria-hidden="true">&times;</span>
                                                                            </button>d
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <Row>
                                                                                <Col lg={6}>
                                                                                    <LNGCargoQuality
                                                                                        VesselID={VesselID}
                                                                                        reportId={n2adjustment?.start_report}
                                                                                        cargoOperation={'loading'}
                                                                                        cardHeader={'LNG Cargo Loading Quality Details'}
                                                                                        form="view-n2Adjustment"
                                                                                        adjustmentDetails={n2adjustment}
                                                                                        setState={setViewDetailsBool}
                                                                                        tableRefresh={loadData}
                                                                                    />
                                                                                </Col>
                                                                                <Col lg={6}>
                                                                                    <LNGCargoQuality
                                                                                        VesselID={VesselID}
                                                                                        reportId={n2adjustment?.end_report}
                                                                                        cargoOperation={'discharging'}
                                                                                        cardHeader={'LNG Cargo Discharging Quality Details'}
                                                                                        form="view-n2Adjustment"
                                                                                        adjustmentDetails={n2adjustment}
                                                                                        setState={setViewDetailsBool}
                                                                                        tableRefresh={loadData}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Modal>
                                                                </td>
                                                                <td className='align-middle text-center p-2'>
                                                                    <Button type='button'
                                                                        className='btn btn-primary waves-effect btn-label waves-light'
                                                                        color='primary'
                                                                        id={`disapprove_btn${n2?.id}`}
                                                                        onClick={() => {
                                                                            setN2Adjustment(n2);
                                                                            setDisapproveBool(true);
                                                                        }}
                                                                    ><i className="bx bx-left-arrow-circle label-icon"></i>
                                                                        Mark as pending
                                                                    </Button>
                                                                </td>
                                                            </>
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                        {disapproveBool === true &&
                                            <PopOver
                                                target={`disapprove_btn${n2adjustment?.id}`}
                                                handleClick={disapproveN2Correction}
                                                message={'Are you sure you want to disapprove?'}
                                                state={disapproveBool}
                                                setState={setDisapproveBool}
                                            />
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                    {tableDetails?.n2Adjustment?.length > tableDetails?.size &&
                        <AdjustmentPaginationComponent
                            count={count}
                            setCount={setCount}
                            tableDetails={tableDetails}
                            setTableDetails={setTableDetails}
                        />
                    }
                </>
            }
        </>
    )
}

export default ApprovedN2Adjustment