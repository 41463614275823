import apiGlobal from "global/api.global";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Form, Input, Label, Row } from "reactstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../";
import "../../../global/GlobalCSS.css";
import { successToast } from "Components/Toasts";

const Vse = () => {
    const [vessels, setVessels] = useState<Array<any>>([]);
    const [vseEnable, setVseEnable] = useState(true);
    const { VesselID } = useSelector((state: RootState) => state.Reporting);

    let vse = {
        is_vse: false,
        vse_value: 0,
    };

    useEffect(() => {
        const loadVessels = async () => {
            return apiGlobal(`/vessel_general_information/`).then((response) => {
                setVessels(response.data);
                return response.data;
            });
        };
        loadVessels();
    }, []);
    const Vssl = vessels.find((rec: any) => rec.vessel === VesselID);

    const handleVSECheck = (e: any) => {
        if (e.target.checked === true) {
            setVseEnable(false);
        } else {
            setVseEnable(true);
        }
    };

    const handleVSEText = (e: any) => {
        vse.is_vse = !vseEnable;
        vse.vse_value = e.target.value;
    };

    const VSESubmit = () => {
        apiGlobal
            .patch(`/vessel_general_information/${Vssl.id}/`, vse)
            .then((res) => {
                successToast("Data saved successfully!");
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <Card>
                <CardBody>
                    <Form
                        autoComplete="off"
                        onSubmit={(e) => {
                            e.preventDefault();
                            VSESubmit();
                        }}
                    >
                        <Row>
                            <div className="d-flex flex-wrap gap-5">
                                <Label>Is VSE mentioned in EEDI / EEXI?</Label>
                                <div className="form-check mb-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="vseCheckbox"
                                        onChange={handleVSECheck}
                                    />
                                </div>
                                <div className="form-floating mb-3">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="vseTextbox"
                                        name="shaft_power"
                                        placeholder="example"
                                        onBlur={handleVSEText}
                                        disabled={vseEnable}
                                    />
                                    <Label for="shaft_power">VSE value</Label>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className="d-flex flex-wrap gap-5 grp_justify_right">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn_size4_5_cstm"
                                >
                                    Save
                                </Button>
                                <Button
                                    type="reset"
                                    color="danger"
                                    className="btn_size4_5_cstm"
                                >
                                    Reset
                                </Button>
                            </div>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </>
    );
};

export default Vse;
