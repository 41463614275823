import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { useQuery } from 'react-query';
import { Col, Row, Button, Form, Input, Label, Card, CardBody, CardHeader, CardFooter } from "reactstrap";
import { loadLubeOilROBObject, loadPreviousLubeOilROB, loadVesselLubeOils } from 'VesselMaster/vesselMaster.hooks';
import { queryKeyes } from 'shared/queryKeys';
import ReportDetailsHeader from 'Components/ReportDetailsHeader';
import { getInputs } from '../EngineerReport.model';
import { Field, FieldArray, FieldProps, Formik } from 'formik';
import { errorToast, successToast } from 'Components/Toasts';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import apiGlobal from 'global/api.global';
import { queryClient } from 'react-query/queryClient';
import { errResponse } from 'GenericForms/Helper';
import env from 'environment/env';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import ErrorTooltip from 'Components/ErrorTooltip';

interface LubeOilROBType {
    ReportID: number,
    VesselID: number,
    VoyageID: number,
    activeTab: number,
    toggleTab: any
}

const LubeOilROBComponent = ({
    ReportID,
    VesselID,
    VoyageID,
    activeTab,
    toggleTab,
}: LubeOilROBType) => {
    /** State variables */
    let { LO_ROB_inputs } = getInputs('fo_lo_rob');
    const [previouslubeOilROB, setPreviousLubeOilROB] = useState<Array<any>>([]);
    /** State variables end */

    /** Queries */
    /** Lube oils on vessel */
    const { data: VesselLubeOils, isLoading: VesselLubeOilsLoading, isError: VesselLubeOilsError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.VesselLubeOils.key, VesselID],
            async () => { return await loadVesselLubeOils(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );
    /** ROB of lube oil from previous report */
    const { data: previousLubeOilROB, isLoading: previousLubeOilROBLoading, isError: previousLubeOilROBError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.PreviousLubeOilROB.key, VesselID, ReportID],
            async () => { return await loadPreviousLubeOilROB(VesselID, ReportID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );
    /** Lube oil ROB object used for edit */
    const { data: LOROBObject, isLoading: LOROBObjectLoading, isError: LOROBObjectError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.LubeOilROBObject.key, VesselID, ReportID],
            async () => { return await loadLubeOilROBObject(VesselID, ReportID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );
    /** Queries end */

    /** Assign initial values to formik object */
    const getintialValueLubeOilROB = () => {
        let obj: any[] = [];
        if (LOROBObject?.length > 0 && LOROBObject[0].id > 0) {
            return LOROBObject;
        } else if (
            !VesselLubeOilsLoading &&
            (VesselLubeOils && obj.length <= VesselLubeOils.length)) {
            VesselLubeOils?.filter((item: any) => item.status === true)?.map((lube: any) => {
                obj.push({
                    lrob: previousLubeOilROB?.filter((item: any) => item.lube_oil_name === lube.id).map((lube: any) => { return lube.lrob })[0] ?? 0,
                    previous_rob: previousLubeOilROB?.filter((item: any) => item.lube_oil_name === lube.id).map((lube: any) => { return lube.lrob })[0] ?? 0,
                    lo_consumption: 0,
                    lube_oil_name: lube.id,
                    lube_name: lube.lube_name,
                    vessel: VesselID,
                    voyage_information: VoyageID,
                    vessel_reporting_information: ReportID
                })
                return lube;
            })
            return obj;
        } else {
            return [
                {
                    lrob: 0,
                    previous_rob: 0,
                    lo_consumption: 0,
                    lube_oil_name: 0,
                    vessel: VesselID,
                    voyage_information: VoyageID,
                    vessel_reporting_information: ReportID
                }
            ]
        }
    }

    /** useEffect start */
    useEffect(() => {
        let prevArr: any[] = [];
        if (LOROBObject && LOROBObject[0]?.id > 0) {
            LOROBObject.map((rob: any) => {
                return prevArr.push({
                    lubeOil: rob.lube_oil_name,
                    rob: rob.lrob
                });
            })
        } else if (previousLubeOilROB) {
            previousLubeOilROB.map((rob: any) => {
                return prevArr.push({
                    lubeOil: rob.lube_oil_name,
                    rob: rob.lrob
                });
            })
        }
        setPreviousLubeOilROB(prevArr);
    }, [previousLubeOilROB, LOROBObject])
    /** useEffect end */

    /** Lube oil Formik object */
    const LubeOilFormik = {
        initialValues: {
            lubeOil: getintialValueLubeOilROB()
        },
        validationSchema: Yup.object().shape({
            lubeOil: Yup.array(
                Yup.object({
                    lo_consumption: Yup.string().matches(/^\d{1,7}(\.\d{0,2})?$/, commonValidationMessages.before7after2).required(commonValidationMessages.required),
                })
            )
        })
    }

    const handleLubeOilConsumption = (e: any, values: any, fieldValue: any, fieldName: any, lubeId: number) => {
        fieldValue(fieldName, (previouslubeOilROB.filter((item: any) => item.lubeOil === lubeId)[0]?.rob - e.target.value));
    }
    return (
        <Card className='p-0 mb-0 border-0'>
            <CardHeader className='p-2 border-bottom-0'>
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Lube Oil ROB</h4>
                            <p className="card-title-desc pos-start">All readings since last report</p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {(VesselLubeOilsLoading ||
                previousLubeOilROBLoading ||
                LOROBObjectLoading) && <Loading message='Loading required data!' />}
            {(VesselLubeOilsError ||
                previousLubeOilROBError) && <ErrorComponent message='Unable to load required data!' />}
            {LOROBObjectError && getintialValueLubeOilROB()}
            {!(VesselLubeOilsLoading ||
                previousLubeOilROBLoading ||
                LOROBObjectLoading) &&
                !(VesselLubeOilsError ||
                    previousLubeOilROBError) &&
                <Formik
                    onSubmit={(values: any, actions: any) => {
                        actions.setSubmitting(false);
                        const handleResponse = (response: any) => {
                            if (response.status === 201 || response.status === 200) {
                                successToast("Data saved successfully!");
                                queryClient.invalidateQueries(queryKeyes.vessel.PreviousLubeOilROB.key);
                                queryClient.invalidateQueries(queryKeyes.vessel.LubeOilROBObject.key);
                                if (env?.form_validation === true) {
                                    toggleTab(activeTab + 1);
                                }
                            }
                        }
                        /** Lube oil ROB submit */
                        if (LOROBObject?.length > 0 && LOROBObject[0].id > 0) {
                            values.lubeOil.map((lube: any) => {
                                apiGlobal.put(`/lo_rob/${lube.id}/`, lube).then(res => {
                                    handleResponse(res);
                                }).catch(err => {
                                    if (errResponse.includes(err.response.status)) {
                                        errorToast("Internal error occured, please contact the admin")
                                    }
                                });
                                return '';
                            })
                        } else {
                            apiGlobal.post(`/lo_rob/`, values.lubeOil).then(res => {
                                handleResponse(res);
                            }).catch(err => {
                                if (errResponse.includes(err.response.status)) {
                                    errorToast("Internal error occured, please contact the admin")
                                }
                            });
                        }
                    }}
                    initialValues={LubeOilFormik.initialValues}
                    validationSchema={LubeOilFormik.validationSchema}
                >
                    {({ values, errors, handleSubmit, handleChange, setFieldValue, setErrors, touched, handleBlur }:
                        { values: any, errors: any, handleSubmit: any, handleChange: any, setFieldValue: any, setErrors: any, touched: any, handleBlur: any }) => (
                        <Form className="needs-validation" autoComplete="off" onSubmit={handleSubmit}>
                            <CardBody className='px-2 py-0'>
                                <Row>
                                    <Col sm="12">
                                        {VesselLubeOils &&
                                            <div className="table-responsive p-0">
                                                <table className="table mb-2">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="p-2 sr-no-width align-middle">#</th>
                                                            {
                                                                LO_ROB_inputs
                                                                    .filter((table: any) => table.table_columns === true)
                                                                    .sort((a: any, b: any) => a.table_columns_sequence - b.table_columns_sequence)
                                                                    .map(({ name, ...props }, index: number) => {
                                                                        return (
                                                                            <th key={index} className={props.label === 'Lube oil' ? 'text-left align-middle p-2' : props.className + ' align-middle'}>{props.label}</th>
                                                                        )
                                                                    })
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <FieldArray name='lubeOil'>
                                                            {() => (
                                                                <>
                                                                    {values &&
                                                                        values?.lubeOil?.map((lubeOil: any, index: number) => {
                                                                            return (
                                                                                <tr key={lubeOil.id}>
                                                                                    <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                                                    <td className='p-2 align-middle'>{lubeOil.lube_name}</td>
                                                                                    <td className='p-2 text-center'>
                                                                                        <div className='d-inline-block'>
                                                                                            <div className="input-group">
                                                                                                <Field name={`lubeOil.${index}.lo_consumption`}>
                                                                                                    {() => (
                                                                                                        <Input
                                                                                                            type="text"
                                                                                                            className="form-control max-width-7 text-right"
                                                                                                            id={`lo_consumption-${index}`}
                                                                                                            name={`lubeOil.${index}.lo_consumption`}
                                                                                                            onBlur={handleBlur}
                                                                                                            onChange={(e: any) => {
                                                                                                                handleChange(e);
                                                                                                                handleLubeOilConsumption(e, values, setFieldValue,
                                                                                                                    `lubeOil.${index}.lrob`, lubeOil.lube_oil_name)
                                                                                                            }}
                                                                                                            defaultValue={values?.lubeOil[index]?.lo_consumption}
                                                                                                        />)}</Field>
                                                                                                <div className="input-group-text">ltr</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {errors?.lubeOil && touched?.lubeOil && touched?.lubeOil[index]?.lo_consumption &&
                                                                                            errors?.lubeOil[index]?.lo_consumption && env?.form_validation === true &&
                                                                                            <ErrorTooltip
                                                                                                target={`lo_consumption-${index}`}
                                                                                                message={errors?.lubeOil[index]?.lo_consumption}
                                                                                                open={(errors?.lubeOil && errors?.lubeOil[index]?.lo_consumption) ? true : false}
                                                                                            />
                                                                                        }
                                                                                    </td>
                                                                                    <td className='p-2 text-center'>
                                                                                        <div className='d-inline-block'>
                                                                                            <div className="input-group">
                                                                                                <Field name={`lubeOil.${index}.lrob`}>
                                                                                                    {({ field }: FieldProps) => (
                                                                                                        <Input
                                                                                                            type="text"
                                                                                                            className="form-control max-width-7 text-right"
                                                                                                            id={lubeOil.id}
                                                                                                            name={field.name}
                                                                                                            onBlur={(e: any) => handleChange(e)}
                                                                                                            disabled={values?.lubeOil[index]?.lrob <= 0 ? false : true}
                                                                                                            value={values?.lubeOil[index]?.lrob}
                                                                                                        />
                                                                                                    )}
                                                                                                </Field>
                                                                                                <div className="input-group-text">ltr</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            )}
                                                        </FieldArray>
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className='p-2 py-3'>
                                <Row className="ele_row1">
                                    <div className="d-flex flex-wrap gap-5">
                                        <Button type="submit" color="primary" className="btn_size_cstm pos-end" onClick={(e) => {
                                            if (env?.form_validation === false) {
                                                toggleTab(activeTab + 1);
                                            }
                                        }}>Next <i className="bx bx-chevron-right ms-1" /></Button>
                                        <Button type="button" color="primary" className="btn_size_cstm" onClick={(e) => {
                                            setErrors({});
                                            toggleTab(activeTab - 1);
                                        }}><i className="bx bx-chevron-left me-1" /> Previous</Button>
                                    </div>
                                </Row>
                            </CardFooter>
                            <FormValuesDebug values={[values, errors, LubeOilFormik.initialValues]} />
                        </Form>
                    )}
                </Formik>
            }
        </Card>
    )
}

export default LubeOilROBComponent