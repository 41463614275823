import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
    Col,
    Row,
    Label,
    Button,
    Form,
    Input,
    CardFooter,
    Card,
    CardBody,
    CardHeader,
} from "reactstrap";
import { useQuery } from "react-query";
import AsyncSelect from "react-select/async";
import ReportDetailsHeader from "Components/ReportDetailsHeader";
import { queryKeyes } from "shared/queryKeys";
import { getInputs } from "../EngineerReport.model";
import {
    loadLOBunkering,
    loadLubeOilROBObject,
    loadOtherPorts,
    loadVesselLubeOils,
} from "VesselMaster/vesselMaster.hooks";
import { Field, FieldArray, FieldProps, Formik } from "formik";
import {
    dataTimeFormat,
    errResponse,
    searchCountries,
    searchPorts,
} from "GenericForms/Helper";
import Loading from "Components/Loading";
import ErrorComponent from "Components/ErrorComponent";
import { errorToast, successToast } from "Components/Toasts";
import apiGlobal from "global/api.global";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import { queryClient } from "react-query/queryClient";
import { commonValidationMessages } from "Components/ValidationErrorMessages";
import env from "environment/env";
import { PortConstant } from "shared/constants";
import { customStyle } from "shared/CommonCSS";
import ErrorTooltip from "Components/ErrorTooltip";

interface LubeOilBunkeringType {
    ReportID: number;
    VesselID: number;
    VoyageID: number;
    activeTab: number;
    toggleTab: any;
    tabsIdList: any[];
    record: any;
    toggleDynamicTabs: any;
    previousTabIndex: number;
    lastRecord: any;
    tabsList: any[];
}

const LubeOilBunkeringComponent = ({
    ReportID,
    VesselID,
    VoyageID,
    activeTab,
    toggleTab,
    tabsIdList,
    record,
    toggleDynamicTabs,
    previousTabIndex,
    lastRecord,
    tabsList,
}: LubeOilBunkeringType) => {
    /** State variables */
    let { LO_Bunkering_inputs } = getInputs("lo_bunkering");
    const [port, setPort] = useState(0);
    const [otherPort, setOtherPort] = useState(false);
    const [otherPortName, setOtherPortName] = useState("");
    const [otherCountry, setOtherCountry] = useState(0);
    const [addOtherPort, setAddOtherPort] = useState(false);
    const [otherPortId, setOtherPortId] = useState(null);
    /** State variables end */

    /** Queries */
    /** Lube oils on vessel */
    const {
        data: VesselLubeOils,
        isLoading: VesselLubeOilsLoading,
        isError: VesselLubeOilsError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.VesselLubeOils.key, VesselID],
        async () => {
            return await loadVesselLubeOils(VesselID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );
    /** Lube oil bunkering object used for edit */
    const {
        data: LOBunkeringObject,
        isLoading: LOBunkeringObjectLoading,
        isError: LOBunkeringObjectError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.LOBunkeringObject.key, VesselID, ReportID],
        async () => {
            return await loadLOBunkering(VesselID, ReportID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );
    /** Lube oil ROB object */
    const {
        data: LOROBObject,
        isLoading: LOROBObjectLoading,
        isError: LOROBObjectError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.LubeOilROBObject.key, VesselID, ReportID],
        async () => {
            return await loadLubeOilROBObject(VesselID, ReportID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );
    /** Other Ports from master */
    const {
        data: OtherPortsObject,
        isLoading: OtherPortsLoading,
    }: { data: any; isLoading: any; isError: any } = useQuery(
        [queryKeyes.masters.OtherPortMaster.key, VesselID, ReportID],
        async () => {
            return await loadOtherPorts();
        },
        { staleTime: Infinity }
    );
    /** Queries end */

    /** Assign initial values to formik object */
    const getintialValueLubeOilBunkering = () => {
        let arr: any[] = [];
        if (LOBunkeringObject?.length > 0 && LOBunkeringObject[0].id > 0) {
            return LOBunkeringObject;
        } else if (
            !VesselLubeOilsLoading &&
            VesselLubeOils &&
            arr.length <= VesselLubeOils.length
        ) {
            VesselLubeOils?.map((lube: any) => {
                return arr.push({
                    quantity_bunkered: 0,
                    date_of_bunkering: null,
                    vessel_lube_oil_name: lube.id,
                    port_of_bunkering: null,
                    other_port: null as number,
                    other_port_name: null as string,
                    other_country: null as number,
                    other_country_name: null as string,
                    vessel: VesselID,
                    voyage_information: VoyageID,
                    vessel_reporting_information: ReportID,
                });
            });
        }
        return arr;
    };

    /** useEffect start */
    useEffect(() => {
        if (
            LOBunkeringObject?.length > 0 &&
            LOBunkeringObject[0].id > 0 &&
            LOBunkeringObject[0]?.port_precedence_id === PortConstant.OTHER
        ) {
            setOtherPort(true);
            setPort(LOBunkeringObject[0]?.port_of_bunkering);
            setOtherPortId(LOBunkeringObject[0]?.other_port);
            setOtherPortName(LOBunkeringObject[0]?.other_port_name);
            setOtherCountry(LOBunkeringObject[0]?.other_country);
        }
    }, [LOBunkeringObject]);
    useEffect(() => {
        if (LOBunkeringObject !== undefined && LOBunkeringObject.length > 0) {
            setPort(LOBunkeringObject[0]?.port_of_bunkering);
        }
    }, [LOBunkeringObject]);
    /** useEffect end */

    /** Lube oil bunkering Formik object */
    const LubeOilBunkeringFormik = {
        initialValues: {
            lubeOil: getintialValueLubeOilBunkering(),
        },
        validationSchema: Yup.object().shape({
            lubeOil: Yup.array(
                Yup.object({
                    quantity_bunkered: Yup.string()
                        .matches(/^\d{1,7}(\.\d{0,2})?$/, "Please enter upto 7 digits only")
                        .required(commonValidationMessages.required),
                    port_of_bunkering: Yup.number().required(
                        commonValidationMessages.required
                    ),
                    date_of_bunkering: Yup.date()
                        .min(dataTimeFormat(lastRecord?.reporting_time_utc, true)) //lastRecord?.reporting_time_utc
                        .max(dataTimeFormat(record?.reporting_time_utc, true))
                        .required(commonValidationMessages.required),
                    other_port: Yup.string().when(
                        "$fieldAvailability",
                        (field: any, schema) => {
                            return !(
                                otherPort === true &&
                                OtherPortsObject &&
                                OtherPortsObject?.length > 0
                            )
                                ? schema.nullable()
                                : schema.required(commonValidationMessages.required);
                        }
                    ),
                    other_port_name: Yup.string().when(
                        "$fieldAvailability",
                        (field: any, schema) => {
                            return !(addOtherPort === true || !OtherPortsObject)
                                ? schema.nullable()
                                : schema.required(commonValidationMessages.required);
                        }
                    ),
                    other_country: Yup.number().when(
                        "$fieldAvailability",
                        (field: any, schema) => {
                            return !(addOtherPort === true || !OtherPortsObject)
                                ? schema.nullable()
                                : schema.required(commonValidationMessages.required);
                        }
                    ),
                })
            ),
        }),
    };

    /** Post submit actions based on response */
    const handleResponse = (response: any) => {
        if (response.status === 201 || response.status === 200) {
            successToast("Data saved successfully!");
            queryClient.invalidateQueries(queryKeyes.vessel.LOBunkeringObject.key);
            if (env?.form_validation === true) {
                toggleDynamicTabs(previousTabIndex + 1);
            }
        }
    };

    /** PUT request for LO Bunkering */
    const putLOBunkering = (value: any) => {
        apiGlobal
            .put(`/lube_oil_bunkering/${value.id}/`, value)
            .then((res) => {
                handleResponse(res);
            })
            .catch((err) => {
                if (errResponse.includes(err.response.status)) {
                    errorToast("Internal error occured, please contact the admin");
                }
            });
    };

    /** POST request for LO Bunkering */
    const postLOBunkering = (values: any) => {
        apiGlobal
            .post(`/lube_oil_bunkering/`, values)
            .then((res) => {
                handleResponse(res);
            })
            .catch((err) => {
                if (errResponse.includes(err.response.status)) {
                    errorToast("Internal error occured, please contact the admin");
                }
            });
    };

    /** Submit function for LO Bunkering */
    const LOBunkeringSubmit = (values: any) => {
        /** Select port of bunkering */
        values.lubeOil.map((lube: any) => {
            lube.port_of_bunkering = port;
            lube.other_port = otherPortId;
            lube.other_port_name = otherPortName;
            lube.other_country = otherCountry;
            return "";
        });
        /** Lube oil bunkering submit */
        if (LOBunkeringObject?.length > 0 && LOBunkeringObject[0].id > 0) {
            values.lubeOil.forEach((lube: any) => {
                putLOBunkering(lube);
            });
        } else {
            postLOBunkering(values.lubeOil);
        }

        /** Update Lube oil ROB */
        LOROBObject?.map((lube: any) => {
            const bunker: any = values?.lubeOil?.filter(
                (item: any) => item.vessel_lube_oil_name === lube.lube_oil_name
            )[0]?.quantity_bunkered;
            if (bunker > 0) {
                const rob: number =
                    parseFloat(lube.previous_rob) -
                    parseFloat(lube.lo_consumption) +
                    parseFloat(bunker);
                lube.lrob = rob;
                apiGlobal
                    .put(`/lo_rob/${lube.id}/`, lube)
                    .then((res) => {
                        handleResponse(res);
                    })
                    .catch((err) => {
                        if (errResponse.includes(err.response.status)) {
                            errorToast("Internal error occured, please contact the admin");
                        }
                    });
            }
            return "";
        });
    };

    return (
        <Card className="p-0 mb-0 border-0">
            <CardHeader className="p-2">
                <Row>
                    <Col>
                        <h4 className="page_title mb-0">Lube Oil Bunkering</h4>
                        <p className="card-title-desc pos-start">
                            All readings since last report
                        </p>
                    </Col>
                    <Col>
                        <ReportDetailsHeader />
                    </Col>
                </Row>
            </CardHeader>
            {(VesselLubeOilsLoading ||
                LOBunkeringObjectLoading ||
                LOROBObjectLoading ||
                OtherPortsLoading) && <Loading message="Loading required data!" />}
            {(VesselLubeOilsError || LOROBObjectError) && (
                <ErrorComponent message="Unable to load required data!" />
            )}
            {LOBunkeringObjectError && getintialValueLubeOilBunkering()}
            {!(
                VesselLubeOilsLoading ||
                LOBunkeringObjectLoading ||
                LOROBObjectLoading ||
                OtherPortsLoading
            ) &&
                !(VesselLubeOilsError || LOROBObjectError) && (
                    <Formik
                        onSubmit={(values: any, actions: any) => {
                            actions.setSubmitting(false);
                            LOBunkeringSubmit(values);
                        }}
                        initialValues={LubeOilBunkeringFormik.initialValues}
                        validationSchema={LubeOilBunkeringFormik.validationSchema}
                    >
                        {({
                            values,
                            errors,
                            handleSubmit,
                            handleChange,
                            setErrors,
                            touched,
                            handleBlur,
                            setFieldTouched,
                        }: {
                            values: any;
                            errors: any;
                            handleSubmit: any;
                            handleChange: any;
                            setErrors: any;
                            touched: any;
                            handleBlur: any;
                            setFieldTouched: any;
                        }) => (
                            <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                                <CardBody className="px-2 py-0">
                                    <Row>
                                        {VesselLubeOils && (
                                            <>
                                                <Col lg={4} className="mb-2">
                                                    <Label
                                                        className="asteric mb-0"
                                                        for="port_of_bunkering_0"
                                                    >
                                                        Port of bunkering
                                                    </Label>
                                                    <Field name={`lubeOil.0.port_of_bunkering`}>
                                                        {({ field, form }: FieldProps) => (
                                                            <AsyncSelect
                                                                name={field.name}
                                                                inputId="port_of_bunkering_0"
                                                                cacheOptions
                                                                defaultOptions
                                                                loadOptions={(e: any) => searchPorts(e)}
                                                                getOptionLabel={(e) => e.port_name}
                                                                getOptionValue={(e) => e.id}
                                                                onBlur={() => setFieldTouched(field.name, true)}
                                                                onChange={(e: any) => {
                                                                    setPort(e.id);
                                                                    values?.lubeOil?.forEach((bunker: any) => {
                                                                        bunker.port_of_bunkering = e?.id;
                                                                    });
                                                                    if (
                                                                        e?.precedence_id === PortConstant.OTHER &&
                                                                        addOtherPort === false
                                                                    ) {
                                                                        setOtherPort(true);
                                                                    } else if (
                                                                        e?.precedence_id !== PortConstant.OTHER
                                                                    ) {
                                                                        setOtherPort(false);
                                                                        setAddOtherPort(false);
                                                                        setOtherPortId(null);
                                                                        setOtherPortName(null);
                                                                        setOtherCountry(null);
                                                                        if (
                                                                            values?.lubeOil &&
                                                                            values?.lubeOil?.length > 0
                                                                        ) {
                                                                            values?.lubeOil?.forEach(
                                                                                (bunker: any, index: any) => {
                                                                                    form.setFieldValue(
                                                                                        `lubeOil.${index}.other_port`,
                                                                                        null
                                                                                    );
                                                                                    form.setFieldValue(
                                                                                        `lubeOil.${index}.other_port_name`,
                                                                                        null
                                                                                    );
                                                                                    form.setFieldValue(
                                                                                        `lubeOil.${index}.other_country`,
                                                                                        null
                                                                                    );
                                                                                }
                                                                            );
                                                                        }
                                                                    }
                                                                }}
                                                                menuPortalTarget={document.body}
                                                                defaultValue={
                                                                    LOBunkeringObject?.length > 0 &&
                                                                        LOBunkeringObject[0].id > 0
                                                                        ? {
                                                                            id: LOBunkeringObject[0]
                                                                                .port_of_bunkering,
                                                                            port_name:
                                                                                LOBunkeringObject[0]
                                                                                    .port_of_bunkering_name,
                                                                        }
                                                                        : null
                                                                }
                                                                noOptionsMessage={(e: any) => {
                                                                    if (e?.inputValue?.toString()?.length > 2) {
                                                                        return "Please select the Other option and enter the port name in the textbox provided";
                                                                    }
                                                                    return "Please enter the first 3 characters of port name";
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    {errors.lubeOil &&
                                                        touched?.lubeOil &&
                                                        touched?.lubeOil[0]?.port_of_bunkering_0 &&
                                                        errors?.lubeOil[0]?.port_of_bunkering &&
                                                        env?.form_validation === true && (
                                                            <ErrorTooltip
                                                                target={`port_of_bunkering_0`}
                                                                message={errors?.lubeOil[0]?.port_of_bunkering}
                                                                open={
                                                                    errors?.lubeOil[0]?.port_of_bunkering
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        )}
                                                </Col>
                                                {otherPort === true &&
                                                    OtherPortsObject &&
                                                    OtherPortsObject?.length > 0 && (
                                                        <Col lg={4}>
                                                            <Label
                                                                className="mb-0 asteric"
                                                                for="other_port_name_dropdown"
                                                            >
                                                                Other port
                                                            </Label>
                                                            <Field name="lubeOil.0.other_port">
                                                                {({ field, form }: FieldProps) => (
                                                                    <AsyncSelect
                                                                        name={field.name}
                                                                        inputId="other_port_name_dropdown"
                                                                        cacheOptions
                                                                        defaultOptions
                                                                        loadOptions={() => loadOtherPorts()}
                                                                        getOptionLabel={(e: any) => e.port_name}
                                                                        getOptionValue={(e: any) => e.id}
                                                                        onBlur={() =>
                                                                            form.setFieldTouched(field.name, true)
                                                                        }
                                                                        onChange={(selectedOption: any) => {
                                                                            values?.lubeOil?.forEach(
                                                                                (bunker: any, index: any) => {
                                                                                    form.setFieldValue(
                                                                                        `lubeOil.${index}.other_port`,
                                                                                        selectedOption.id
                                                                                    );
                                                                                    form.setFieldValue(
                                                                                        `lubeOil.${index}.other_port_name`,
                                                                                        selectedOption?.port_name
                                                                                    );
                                                                                    form.setFieldValue(
                                                                                        `lubeOil.${index}.other_country`,
                                                                                        selectedOption?.country
                                                                                    );
                                                                                }
                                                                            );
                                                                            setOtherPortId(selectedOption?.id);
                                                                            setOtherPortName(
                                                                                selectedOption?.port_name
                                                                            );
                                                                            setOtherCountry(selectedOption?.country);
                                                                        }}
                                                                        defaultValue={
                                                                            LOBunkeringObject &&
                                                                            LOBunkeringObject[0]?.id && {
                                                                                id: LOBunkeringObject[0]?.other_port,
                                                                                port_name:
                                                                                    LOBunkeringObject[0]?.other_port_name,
                                                                            }
                                                                        }
                                                                        menuPortalTarget={document.body}
                                                                        styles={customStyle}
                                                                        noOptionsMessage={(e: any) => {
                                                                            if (
                                                                                e?.inputValue?.toString()?.length > 2
                                                                            ) {
                                                                                return "Please select the Other option and enter the port name in the textbox provided";
                                                                            }
                                                                            return "Please enter the first 3 characters of port name";
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <p
                                                                className="link_color_blue pointer"
                                                                onClick={() => {
                                                                    setAddOtherPort(true);
                                                                    setOtherPort(false);
                                                                    values?.lubeOil?.forEach(
                                                                        (bunker: any, index: any) => {
                                                                            values.lubeOil[index].other_port = null;
                                                                            values.lubeOil[index].other_port_name =
                                                                                null;
                                                                            values.lubeOil[index].other_country =
                                                                                null;
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                Click here to add new port
                                                            </p>
                                                        </Col>
                                                    )}
                                                {(addOtherPort === true || !OtherPortsObject) && (
                                                    <>
                                                        <Col lg={4}>
                                                            <Label className="mb-0 asteric">
                                                                Other port name
                                                            </Label>
                                                            <Field name={`lubeOil.0.other_port_name`}>
                                                                {({ field, form }: FieldProps) => (
                                                                    <Input
                                                                        type="text"
                                                                        name={`lubeOil.0.other_port_name`}
                                                                        id="other_port_name"
                                                                        className="form-control"
                                                                        onBlur={(e: any) => {
                                                                            setOtherPortName(e.target.value);
                                                                            if (
                                                                                values?.lubeOil &&
                                                                                values?.lubeOil?.length > 0
                                                                            ) {
                                                                                values?.lubeOil?.forEach(
                                                                                    (bunker: any, index: any) => {
                                                                                        form.setFieldValue(
                                                                                            `lubeOil.${index}.other_port_name`,
                                                                                            e.target.value
                                                                                        );
                                                                                    }
                                                                                );
                                                                            }
                                                                        }}
                                                                        defaultValue={
                                                                            values?.lubeOil[0]?.other_port_name
                                                                        }
                                                                    />
                                                                )}
                                                            </Field>
                                                            {errors?.lubeOil &&
                                                                touched?.lubeOil &&
                                                                touched?.lubeOil[0]?.other_port_name &&
                                                                errors?.lubeOil[0]?.other_port_name &&
                                                                env?.form_validation === true && (
                                                                    <ErrorTooltip
                                                                        target={`other_port_name`}
                                                                        message={
                                                                            errors?.lubeOil[0]?.other_port_name
                                                                        }
                                                                        open={
                                                                            errors &&
                                                                                errors?.lubeOil[0]?.other_port_name
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                )}
                                                        </Col>
                                                        <Col lg={4}>
                                                            <Label className="mb-0 asteric">
                                                                Other country name
                                                            </Label>
                                                            <Field name={`lubeOil.0.other_country`}>
                                                                {({ field, form }: FieldProps) => (
                                                                    <AsyncSelect
                                                                        name={field.name}
                                                                        id={"other_country"}
                                                                        cacheOptions
                                                                        defaultOptions
                                                                        loadOptions={(e: any) => searchCountries(e)}
                                                                        getOptionLabel={(e: any) => e.country_name}
                                                                        getOptionValue={(e: any) => e.id}
                                                                        onBlur={(e) => {
                                                                            form.setFieldTouched(field.name, true);
                                                                        }}
                                                                        onChange={(e: any) => {
                                                                            // form.setFieldTouched(field.name, true);
                                                                            setOtherCountry(e?.id);
                                                                            values?.lubeOil?.forEach(
                                                                                (bunker: any, index: any) => {
                                                                                    form.setFieldValue(
                                                                                        `lubeOil.${index}.other_country`,
                                                                                        e?.id
                                                                                    );
                                                                                }
                                                                            );
                                                                        }}
                                                                        defaultValue={
                                                                            LOBunkeringObject &&
                                                                            LOBunkeringObject[0]?.id > 0 && {
                                                                                id: values?.other_country,
                                                                                country_name:
                                                                                    values?.other_country_name,
                                                                            }
                                                                        }
                                                                        className="select-height"
                                                                        menuPortalTarget={document.body}
                                                                        styles={customStyle}
                                                                        noOptionsMessage={(e: any) => {
                                                                            return "Please enter the first 3 characters of country name";
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                            {errors?.lubeOil &&
                                                                touched?.lubeOil &&
                                                                touched?.lubeOil[0]?.other_country &&
                                                                errors?.lubeOil[0]?.other_country &&
                                                                env?.form_validation === true && (
                                                                    <ErrorTooltip
                                                                        target={`other_country`}
                                                                        message={errors?.lubeOil[0]?.other_country}
                                                                        open={
                                                                            errors &&
                                                                                errors?.lubeOil[0]?.other_country
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                )}
                                                        </Col>
                                                    </>
                                                )}
                                                <div className="table-responsive ">
                                                    <table className="table mb-2">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th className="align-middle px-0 py-2 sr-no-width">
                                                                    #
                                                                </th>
                                                                {LO_Bunkering_inputs.filter(
                                                                    (table: any) => table.table_columns === true
                                                                )
                                                                    .sort(
                                                                        (a: any, b: any) =>
                                                                            a.table_columns_sequence -
                                                                            b.table_columns_sequence
                                                                    )
                                                                    .map(({ name, ...props }, index: number) => {
                                                                        return (
                                                                            <th
                                                                                key={index}
                                                                                className="align-middle p-2"
                                                                            >
                                                                                {props.label}
                                                                            </th>
                                                                        );
                                                                    })}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <FieldArray name="lubeOil">
                                                                {() => (
                                                                    <>
                                                                        {VesselLubeOils &&
                                                                            VesselLubeOils.map(
                                                                                (lubeOil: any, index: number) => (
                                                                                    <tr key={lubeOil.id}>
                                                                                        <td className="align-middle p-2 text-center">
                                                                                            {index + 1}
                                                                                        </td>
                                                                                        <td className="align-middle p-2">
                                                                                            {lubeOil.vessel_lube_oil_name}
                                                                                        </td>
                                                                                        <td className="align-middle p-2">
                                                                                            <div className="input-group">
                                                                                                <Field
                                                                                                    name={`lubeOil.${index}.quantity_bunkered`}
                                                                                                >
                                                                                                    {({
                                                                                                        field,
                                                                                                        form,
                                                                                                    }: FieldProps) => (
                                                                                                        <Input
                                                                                                            type="text"
                                                                                                            className="form-control text-right max-width-7"
                                                                                                            id={`quantity_bunkered_${index}`}
                                                                                                            name={field.name}
                                                                                                            onBlur={(e: any) => {
                                                                                                                handleBlur(e);
                                                                                                                handleChange(e);
                                                                                                            }}
                                                                                                            defaultValue={
                                                                                                                values &&
                                                                                                                values?.lubeOil[index]
                                                                                                                    ?.quantity_bunkered
                                                                                                            }
                                                                                                        />
                                                                                                    )}
                                                                                                </Field>
                                                                                                <div className="input-group-text">
                                                                                                    mt
                                                                                                </div>
                                                                                            </div>
                                                                                            {errors?.lubeOil &&
                                                                                                touched?.lubeOil &&
                                                                                                touched?.lubeOil[index]
                                                                                                    ?.quantity_bunkered &&
                                                                                                errors?.lubeOil[index]
                                                                                                    ?.quantity_bunkered &&
                                                                                                env?.form_validation ===
                                                                                                true && (
                                                                                                    <ErrorTooltip
                                                                                                        target={`quantity_bunkered_${index}`}
                                                                                                        message={
                                                                                                            errors?.lubeOil[index]
                                                                                                                ?.quantity_bunkered
                                                                                                        }
                                                                                                        open={
                                                                                                            errors?.lubeOil &&
                                                                                                                errors?.lubeOil[index]
                                                                                                                    ?.quantity_bunkered
                                                                                                                ? true
                                                                                                                : false
                                                                                                        }
                                                                                                    />
                                                                                                )}
                                                                                        </td>
                                                                                        <td className="align-middle p-2 ps-0">
                                                                                            <div>
                                                                                                <Field
                                                                                                    name={`lubeOil.${index}.date_of_bunkering`}
                                                                                                >
                                                                                                    {({
                                                                                                        field,
                                                                                                        form,
                                                                                                    }: FieldProps) => (
                                                                                                        <input
                                                                                                            type="date"
                                                                                                            id={`date_of_bunkering_${index}`}
                                                                                                            name={field.name}
                                                                                                            min={dataTimeFormat(
                                                                                                                lastRecord?.reporting_time_utc,
                                                                                                                true
                                                                                                            )}
                                                                                                            max={dataTimeFormat(
                                                                                                                record?.reporting_time_utc,
                                                                                                                true
                                                                                                            )}
                                                                                                            onBlur={handleBlur}
                                                                                                            onChange={(e: any) =>
                                                                                                                handleChange(e)
                                                                                                            }
                                                                                                            className="datetimepicker text-uppercase"
                                                                                                            defaultValue={
                                                                                                                values &&
                                                                                                                values?.lubeOil[index]
                                                                                                                    ?.date_of_bunkering
                                                                                                            }
                                                                                                        />
                                                                                                    )}
                                                                                                </Field>
                                                                                            </div>
                                                                                            {errors?.lubeOil &&
                                                                                                touched?.lubeOil &&
                                                                                                touched?.lubeOil[index]
                                                                                                    ?.date_of_bunkering &&
                                                                                                errors?.lubeOil[index]
                                                                                                    ?.date_of_bunkering &&
                                                                                                env?.form_validation ===
                                                                                                true && (
                                                                                                    <ErrorTooltip
                                                                                                        target={`date_of_bunkering_${index}`}
                                                                                                        message={
                                                                                                            errors?.lubeOil[index]
                                                                                                                ?.date_of_bunkering
                                                                                                        }
                                                                                                        open={
                                                                                                            errors?.lubeOil &&
                                                                                                                errors?.lubeOil[index]
                                                                                                                    ?.date_of_bunkering
                                                                                                                ? true
                                                                                                                : false
                                                                                                        }
                                                                                                    />
                                                                                                )}
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            )}
                                                                    </>
                                                                )}
                                                            </FieldArray>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                        )}
                                    </Row>
                                </CardBody>
                                <CardFooter className="p-2 py-3">
                                    <Row className="ele_row1">
                                        <div className="d-flex flex-wrap gap-5">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="btn_size_cstm pos-end"
                                                onClick={(e) => {
                                                    if (env?.form_validation === false) {
                                                        toggleDynamicTabs(previousTabIndex + 1);
                                                    }
                                                }}
                                            >
                                                Next <i className="bx bx-chevron-right ms-1" />
                                            </Button>
                                            {tabsIdList[0] ===
                                                tabsList.filter(
                                                    (item: any) => item.tab === "Lube Oil Bunkering"
                                                )[0]?.id &&
                                                record &&
                                                record.operation_precedence_id === 1 ? (
                                                <Button
                                                    type="button"
                                                    color="primary"
                                                    className="btn_size_cstm"
                                                    onClick={(e) => {
                                                        setErrors({});
                                                        toggleTab(tabsList[0]?.id - 1);
                                                    }}
                                                >
                                                    <i className="bx bx-chevron-left me-1" /> Previous
                                                </Button>
                                            ) : tabsIdList[0] ===
                                                tabsList.filter(
                                                    (item: any) => item.tab === "Lube Oil Bunkering"
                                                )[0]?.id &&
                                                record &&
                                                record.operation_precedence_id !== 1 ? (
                                                <Button
                                                    type="button"
                                                    color="primary"
                                                    className="btn_size_cstm"
                                                    onClick={(e) => {
                                                        setErrors({});
                                                        toggleTab(tabsList[0]?.id - 3);
                                                    }}
                                                >
                                                    <i className="bx bx-chevron-left me-1" /> Previous
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="button"
                                                    color="primary"
                                                    className="btn_size_cstm"
                                                    onClick={(e) => {
                                                        setErrors({});
                                                        toggleDynamicTabs(previousTabIndex - 1);
                                                    }}
                                                >
                                                    <i className="bx bx-chevron-left me-1" /> Previous
                                                </Button>
                                            )}
                                        </div>
                                    </Row>
                                </CardFooter>
                                <FormValuesDebug values={[values, errors, touched]} />
                            </Form>
                        )}
                    </Formik>
                )}
        </Card>
    );
};

export default LubeOilBunkeringComponent;
