import React, { useState } from 'react'
import { Row, Col, Card, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane, CardBody, CardFooter, Button } from 'reactstrap'
import classnames from "classnames";
import ReportDetailsHeader from 'Components/ReportDetailsHeader';
import BunkeringComponent from './BunkeringComponent';
import BunkeringFileUpload from './BunkeringFileUpload';
import { OperationPrecedence } from 'shared/constants';
interface BunkeringType {
    ReportID: number,
    VesselID: number,
    VoyageID: number,
    activeTab: number,
    toggleTab: any
    tabsIdList: any[],
    record: any,
    toggleDynamicTabs: any,
    previousTabIndex: number,
    lastRecord: any,
    tabsList: any[],
}

const Bunkering = ({
    ReportID,
    VesselID,
    VoyageID,
    activeTab,
    toggleTab,
    tabsIdList,
    record,
    toggleDynamicTabs,
    previousTabIndex,
    lastRecord,
    tabsList,
}: BunkeringType) => {
    /** State variables start */
    const [activeAdjustmentsTab, setActiveAdjustmentsTab] = useState('1');
    const [tab, setTab] = useState('disabled');
    /** State variables end */

    /** Change tabs */
    const toggle = (tab: any) => {
        if (activeAdjustmentsTab !== tab) {
            setActiveAdjustmentsTab(tab);
        }
    }

    return (
        <Card className='p-0 mb-0 border-0'>
            <CardHeader className='p-2'>
                <Row>
                    <Col>
                        <h4 className="page_title mb-0">Bunkering</h4>
                        <p className="card-title-desc pos-start">All readings since last report & as per BDN</p>
                    </Col>
                    <Col>
                        <ReportDetailsHeader />
                    </Col>
                </Row>
            </CardHeader>
            <CardBody className='p-0'>
                <Card className='p-0 mb-0 border-0'>
                    <CardHeader className='pb-0'>
                        <Nav tabs className='border-0'>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: activeAdjustmentsTab === "1",
                                    })}
                                    onClick={() => {
                                        setTab('disabled');
                                        toggle("1");
                                    }}
                                >
                                    Step 1
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: activeAdjustmentsTab === "2",
                                    })}
                                    onClick={() => {
                                        setTab('');
                                        toggle("2");
                                    }}
                                >
                                    Step 2
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </CardHeader>
                    <TabContent activeTab={activeAdjustmentsTab} className="p-0">
                        <TabPane tabId="1">
                            <BunkeringComponent
                                ReportID={ReportID}
                                VesselID={VesselID}
                                VoyageID={VoyageID}
                                record={record}
                                lastRecord={lastRecord}
                            />
                        </TabPane>
                        <TabPane tabId="2">
                            <BunkeringFileUpload
                                ReportID={ReportID}
                                VesselID={VesselID}
                                setTab={setTab}
                            />
                        </TabPane>
                    </TabContent>
                </Card>
            </CardBody>
            <CardFooter className='p-2 py-3'>
                <Row className="ele_row1">
                    <div className="d-flex flex-wrap gap-5">
                        <Button type="button" color="primary" className={`btn_size_cstm pos-end ${tab}`} onClick={(e) => {
                            toggleDynamicTabs(previousTabIndex + 1);
                        }}>Next <i className="bx bx-chevron-right ms-1" /></Button>
                        {tabsIdList[0] === tabsList
                            .filter((item: any) => item.tab === 'Bunkering')[0]?.id &&
                            (record && record.operation_precedence_id === OperationPrecedence.SEA_PASSAGE) ?
                            <Button type="button" color="primary" className="btn_size_cstm" onClick={(e) => {
                                toggleTab(tabsList[0]?.id - 1);
                            }}><i className="bx bx-chevron-left me-1" /> Previous</Button> :
                            tabsIdList[0] === tabsList
                                .filter((item: any) => item.tab === 'Bunkering')[0]?.id &&
                                (record && record.operation_precedence_id !== OperationPrecedence.SEA_PASSAGE) ?
                                <Button type="button" color="primary" className="btn_size_cstm" onClick={(e) => {
                                    toggleTab(tabsList[0]?.id - 3);
                                }}><i className="bx bx-chevron-left me-1" />Previous</Button> :
                                <Button type="button" color="primary" className="btn_size_cstm" onClick={(e) => {
                                    toggleDynamicTabs(previousTabIndex - 1);
                                }}><i className="bx bx-chevron-left me-1" />Previous</Button>
                        }
                    </div>
                </Row>
            </CardFooter>
        </Card>
    )
}

export default Bunkering