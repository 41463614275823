import React, { useState, useEffect } from "react";
import { CardBody, TabContent, TabPane, Col, Container, Row, Input, Button, Form, Card, CardHeader, CardFooter } from 'reactstrap';
import Layout from "../../HorizontalMenu/Menu";
import Navbar from "../../HorizontalMenu/Navbar";
import '../../global/GlobalCSS.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../';
import { VesselState, setVesselState } from "../../Store/Generic/ReportingSlice";
import VesselDetailsHeader from "../../Components/VesselDetailsHeader";
import ReportDetailsHeader from "../../Components/ReportDetailsHeader";
import apiGlobal from "../../global/api.global";
import { useQuery } from "react-query";
import { queryKeyes } from "../../shared/queryKeys";
import { AddInfoConstant, EngineStatus, VesselTypeConstant } from "../../shared/constants";
import { allDecofficerReport, PreviousVesselReportingInfo } from "../../VesselMaster/vesselMaster.hooks";
import { CpWarrantyComponent } from "./ReportComponents/CpWarrantyComponent";
import { successToast } from "../../Components/Toasts";
import DraftsCargoLadingComponent from "./ReportComponents/DraftsCargoLadingComponent";
import STSOperationComponent from "./ReportComponents/STSOperationComponent";
import ETADComponent from "./ReportComponents/ETADComponent";
import LfondsAddInformation from "./AdditionalInformation/Lfonds/LfondsAddInformation";
import Loading from "Components/Loading";
import ErrorComponent from "Components/ErrorComponent";
import WeatherData from "./ReportComponents/WeatherData";
import env from "environment/env";
import ProactiveAddInformation from "./AdditionalInformation/Proactive/ProactiveAddInformation";
import CIIAdjustmentComponent from "./ReportComponents/CIIAdjustmentComponent";

const DeckOfficerWizard = () => {
    const [activeTab, setactiveTab] = useState(1);
    const [lastRecord, setLastRecord] = useState({ created_on: '', arrival_datetime: '', arrival_port: '', departure_port: '', departure_datetime: '', reporting_time_utc: '' });
    const [weatherDataId] = useState(1);
    const [CIIAdjustmentId] = useState(2);
    const [stsId] = useState(3);
    const [CPWarrantiesId] = useState(4);
    const [DDCDBLId] = useState(5);
    const [ETAId] = useState(6);
    const [LfondsId] = useState(7);
    const [ProactiveId] = useState(7);
    const [lastTabId] = useState(((env?.additional_info_deck === AddInfoConstant.LFONDS_DECK) ||
        (env?.additional_info_deck === AddInfoConstant.PROACTIVE_DECK)) ? 8 : 7);
    const dispatch = useDispatch();
    const { VesselID, VoyageID, ReportID, Reports, Vessels } = useSelector((state: RootState) => state.Reporting);
    let record = Reports.find((rec: any) => rec.id === ReportID) ?? null;
    let vessel = Vessels.find((rec: any) => rec.id === VesselID) ?? null;

    const allReport: any = useQuery([queryKeyes.vessel.decOfficerAll.key, ReportID],
        async () => { return await allDecofficerReport(ReportID) }, {
        enabled: true,
    });

    function toggleTab(tab: any) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= lastTabId) {
                setactiveTab(tab);
            }
        }
    }

    useEffect(() => {
        const loadLastRecord = async () => {
            await apiGlobal.get(`/vessel_reporting_information/get_previous_by_id/?id=${ReportID}&vessel_id=${VesselID}`).then(res => {
                setLastRecord(res.data);
                return res.data;
            }).catch(err => {
                console.log(err);
            })
        }; loadLastRecord();
    }, [ReportID, VesselID]);

    const { data: PreviousVesselReport, isLoading: PreviousVesselReportLoading, isError: PreviousVesselReportError } = useQuery(
        [queryKeyes.vessel.PreviousVesselReport.key, VesselID, ReportID],
        () => PreviousVesselReportingInfo(ReportID, VesselID),
        { staleTime: Infinity });

    const handleResponse = (response: any) => {
        if (response.status === 201 || response.status === 200) {
            successToast("Data saved successfully!");
        }
    }

    const RemarksSubmit = async () => {
        let report = { ...record }
        report.officer_reporting_status = 2;
        try {
            const response = await apiGlobal.put(`/vessel_reporting_information/${ReportID}/`, report);
            handleResponse(response);
            return response.data
        } catch (err) {
            return null;
        }
    }

    const handleVesselState = (record: VesselState) => {
        dispatch(setVesselState(record))
    }

    const handleSaveandComplete = async () => {
        let report = { ...record }
        report.officer_reporting_status = 3;
        try {
            const response = await apiGlobal.put(`/vessel_reporting_information/${ReportID}/`, report);
            handleResponse(response);
            handleVesselState('VESSEL_REPORTING');
            return response.data;
        } catch (err) {
            return null;
        }
    }

    return (
        <React.Fragment>
            <Layout children={Navbar} />
            <div className="page-content">
                <Container fluid>
                    <Card>
                        <CardHeader className="p-2">
                            <Row>
                                <Col sm="2" className="d-flex align-items-center">
                                    <button color='primary' className='btn btn-primary' onClick={() => { handleVesselState('VESSEL_REPORTING') }}>
                                        <i className="bx bx-chevron-left me-1" /> Back
                                    </button>
                                </Col>
                                <Col sm="10">
                                    <VesselDetailsHeader />
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0">
                            {PreviousVesselReportLoading && <Loading message='Loading required data!' />}
                            {PreviousVesselReportError && <ErrorComponent message='Unable to load required data!' />}
                            {!PreviousVesselReportLoading && !PreviousVesselReportError &&
                                <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                    <TabContent
                                        activeTab={activeTab}
                                    >
                                        <TabPane tabId={weatherDataId} className="tabHeight">
                                            {allReport.isLoading ? <div className="alert alert-info" role="alert">
                                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Data is loading please wait!
                                            </div> : ''}
                                            <WeatherData
                                                lastReocrd={lastRecord}
                                                record={record}
                                                vessel={vessel}
                                                VesselID={VesselID}
                                                VoyageID={VoyageID}
                                                ReportID={ReportID}
                                                activeTab={activeTab}
                                                toggleTab={toggleTab}
                                            />
                                        </TabPane>
                                        <TabPane tabId={CIIAdjustmentId} className="">
                                            <CIIAdjustmentComponent
                                                record={record}
                                                VesselID={VesselID}
                                                ReportID={ReportID}
                                                activeTab={activeTab}
                                                toggleTab={toggleTab}
                                            />
                                        </TabPane>
                                        <TabPane tabId={vessel && record &&
                                            ((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
                                                && record?.is_sts_considered === true) ? stsId : 0}
                                            className="tabHeight">
                                            <STSOperationComponent
                                                record={record}
                                                lastRecord={PreviousVesselReport}
                                                VesselID={VesselID}
                                                ReportID={ReportID}
                                                activeTab={activeTab}
                                                toggleTab={toggleTab}
                                            />
                                        </TabPane>
                                        <TabPane tabId={((record && record.status_name === EngineStatus.ringFullAway) &&
                                            (vessel && (vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
                                                && record?.is_sts_considered === true)) ? CPWarrantiesId :
                                            (((record && record.status_name === EngineStatus.ringFullAway) &&
                                                (vessel && vessel?.vessel_type !== VesselTypeConstant.TANKER)) ||
                                                ((record && record.status_name === EngineStatus.ringFullAway) &&
                                                    (vessel && (vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
                                                        && record?.is_sts_considered === false))) ?
                                                CPWarrantiesId - 1 : - 1}>
                                            <CpWarrantyComponent
                                                VesselID={VesselID}
                                                VoyageID={VoyageID}
                                                ReportID={ReportID}
                                                record={record}
                                                toggleTab={toggleTab}
                                                activeTab={activeTab}
                                                CPWarrantySpeedDataObject={allReport?.data?.cp_warranties_speed}
                                                CpWarrantyFuelObject={allReport?.data?.cp_warranties_fuel}
                                                lastRecord={lastRecord}
                                                vessel={vessel}
                                            />
                                        </TabPane>
                                        <TabPane tabId={((record && record.status_name === EngineStatus.ringFullAway) &&
                                            (vessel && (vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
                                                && record?.is_sts_considered === true)) ? DDCDBLId :
                                            (((record && record.status_name === EngineStatus.ringFullAway) &&
                                                (vessel && vessel?.vessel_type !== VesselTypeConstant.TANKER)) ||
                                                ((record && record.status_name === EngineStatus.ringFullAway) &&
                                                    (vessel && (vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER))
                                                    && record?.is_sts_considered === false) ||
                                                ((record && record.status_name !== EngineStatus.ringFullAway) &&
                                                    (vessel && (vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER))
                                                    && record?.is_sts_considered === true)) ? DDCDBLId - 1 : DDCDBLId - 2}>
                                            <DraftsCargoLadingComponent
                                                record={record}
                                                vessel={vessel}
                                                VesselID={VesselID}
                                                VoyageID={VoyageID}
                                                ReportID={ReportID}
                                                activeTab={activeTab}
                                                toggleTab={toggleTab}
                                            />
                                        </TabPane>
                                        <TabPane tabId={((record && record.status_name === EngineStatus.ringFullAway) &&
                                            (vessel && (vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER))
                                            && record?.is_sts_considered === true) ? ETAId :
                                            (((record && record.status_name === EngineStatus.ringFullAway) &&
                                                (vessel && (vessel?.vessel_type !== VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)))
                                                || ((record && record.status_name === EngineStatus.ringFullAway) &&
                                                    (vessel && (vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER))
                                                    && record?.is_sts_considered === false) ||
                                                ((record && record.status_name !== EngineStatus.ringFullAway) &&
                                                    (vessel && (vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER))
                                                    && record?.is_sts_considered === true)) ? ETAId - 1 : ETAId - 2}>
                                            <ETADComponent
                                                ReportID={ReportID}
                                                VesselID={VesselID}
                                                VoyageID={VoyageID}
                                                activeTab={activeTab}
                                                record={record}
                                                lastRecord={lastRecord}
                                                toggleTab={toggleTab}
                                                etaDataobject={allReport?.data?.expected_time_of_arrival}
                                                key="ETADComponent"
                                            />
                                        </TabPane>
                                        <TabPane tabId={env?.additional_info_deck === AddInfoConstant.LFONDS_DECK ? (((record && record.status_name === EngineStatus.ringFullAway) &&
                                            (vessel && (vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER))
                                            && record?.is_sts_considered === true) ? LfondsId :
                                            (((record && record.status_name === EngineStatus.ringFullAway) &&
                                                (vessel && (vessel?.vessel_type !== VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)))
                                                || ((record && record.status_name === EngineStatus.ringFullAway) &&
                                                    (vessel && (vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER))
                                                    && record?.is_sts_considered === false) ||
                                                ((record && record.status_name !== EngineStatus.ringFullAway) &&
                                                    (vessel && (vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER))
                                                    && record?.is_sts_considered === true)) ? LfondsId - 1 : LfondsId - 2) : 0}>
                                            <LfondsAddInformation
                                                ReportID={ReportID}
                                                VesselID={VesselID}
                                                VoyageID={VoyageID}
                                                activeTab={activeTab}
                                                toggleTab={toggleTab}
                                                record={record}
                                                vessel={vessel}
                                            />
                                        </TabPane>
                                        <TabPane tabId={env?.additional_info_deck === AddInfoConstant.PROACTIVE_DECK ? (((record && record.status_name === EngineStatus.ringFullAway) &&
                                            (vessel && (vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER))
                                            && record?.is_sts_considered === true) ? ProactiveId :
                                            (((record && record.status_name === EngineStatus.ringFullAway) &&
                                                (vessel && (vessel?.vessel_type !== VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)))
                                                || ((record && record.status_name === EngineStatus.ringFullAway) &&
                                                    (vessel && (vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER))
                                                    && record?.is_sts_considered === false) ||
                                                ((record && record.status_name !== EngineStatus.ringFullAway) &&
                                                    (vessel && (vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER))
                                                    && record?.is_sts_considered === true)) ? ProactiveId - 1 : ProactiveId - 2) : 0}>
                                            <ProactiveAddInformation
                                                ReportID={ReportID}
                                                VesselID={VesselID}
                                                VoyageID={VoyageID}
                                                activeTab={activeTab}
                                                toggleTab={toggleTab}
                                                record={record}
                                                lastRecord={lastRecord}
                                            />
                                        </TabPane>
                                        <TabPane className="px-2"
                                            tabId={(((record && record.status_name === EngineStatus.ringFullAway) &&
                                                (vessel && vessel?.vessel_type === VesselTypeConstant.TANKER) && record?.is_sts_considered === true) ? lastTabId :
                                                (((record && record.status_name === EngineStatus.ringFullAway) &&
                                                    (vessel && vessel?.vessel_type !== VesselTypeConstant.TANKER)) ||
                                                    ((record && record.status_name === EngineStatus.ringFullAway) &&
                                                        (vessel && vessel?.vessel_type === VesselTypeConstant.TANKER) && record?.is_sts_considered === false) ||
                                                    ((record && record.status_name !== EngineStatus.ringFullAway) &&
                                                        (vessel && vessel?.vessel_type === VesselTypeConstant.TANKER) && record?.is_sts_considered === true)) ? lastTabId - 1 : lastTabId - 2)}>
                                            <CardHeader className='p-2'>
                                                <div className="text-center mb-2">
                                                    <Row>
                                                        <Col>
                                                            <h5 className="page_title pos-start mb-0 ">Remarks (if any)</h5>
                                                        </Col>
                                                        <Col>
                                                            <ReportDetailsHeader />

                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardHeader>
                                            <Form className="needs-validation"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    RemarksSubmit();
                                                    return false;
                                                }}>
                                                <CardBody className='deck-card-body'>
                                                    <Row className="mt-2">
                                                        <Col lg={6}>
                                                            <Input
                                                                type="textarea"
                                                                className="form-control"
                                                                id="textarea"
                                                                name="remarks"
                                                            />
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                                <CardFooter className='p-2 py-3'>
                                                    <Row className="ele_row1">
                                                        <div className="d-flex flex-wrap gap-5 ">
                                                            <Button type="submit" color="primary" className="btn_size_cstm pos-end12">Save</Button>
                                                            <Button type="button" color="primary" className="btn_size_cstm pos-end" onClick={(e) => {
                                                                handleSaveandComplete();
                                                            }}>Save & Complete</Button>
                                                            <Button type="button" color="primary" className="btn_size_cstm " onClick={(e) => {
                                                                toggleTab(activeTab - 1);
                                                            }}><i className="bx bx-chevron-left me-1" /> Previous</Button>
                                                        </div>
                                                    </Row>
                                                </CardFooter>
                                            </Form>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            }
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default DeckOfficerWizard;
